import React, { useEffect, useRef } from "react"

import type { ComponentType } from "react"

export const withShuffledItems = (Component): ComponentType => {
    const ShuffledComponent = (props) => {
        const componentRef = useRef(null)

        const shuffleAndDisplayItems = () => {
            const container = componentRef.current

            if (!container) return

            // get child nodes of our collection
            let items = Array.from(container.children)

            // Shuffle the array
            for (let i = items.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1))
                ;[items[i], items[j]] = [items[j], items[i]]
            }

            // Re-append items to the container to reflect the new order in the DOM
            items.forEach((item) => {
                // Move item to the end of the container, effectively reordering them
                container.appendChild(item)
            })
        }

        useEffect(() => {
            shuffleAndDisplayItems() // Shuffle and display items on component mount
        }, []) // Empty dependency array ensures this runs once after the initial render

        return <Component ref={componentRef} {...props} />
    }

    return ShuffledComponent
}
